import 'bulma/css/bulma.min.css';
import '../App.css'

const Hero = () => {
    return (
        <div className="hero is-fullheight is-primary has-background">
  <img
    alt="Fill Murray"
    className="hero-background is-transparent"
    src="https://tritex.al/tritex_photos/8.jpeg"
  />
  <div className="hero-body">
    <div className="container">
      <h1 className="title is-1 py-1">Tritex Shpk</h1>
      <h3 className="subtitle is-4 py-1">
        With more than 25 years of experience, Tritex offers large scale manufacturing service for large and small brands. It is a leader in the market and a credited enterprise with several quality management certificates.​
      </h3>
      <a className="button is-medium is-secondary">
            <strong>Contact Us</strong>
          </a>
    </div>
  </div>
</div>

    )
}

export default Hero;