import 'bulma/css/bulma.min.css';
import '../App.css'
import React from 'react';

const Navbar = () => {
  const [isActive, setisActive] = React.useState(false)
      return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item" href="https://tritex.al">
      <img
        src="/logoTritex.png"
        width={112}
        height={28}
      />
    </a>
    <a
      onClick={() => {
        setisActive(!isActive)
      }}
      role="button"
      className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  </div>
  <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
    <div className="navbar-start">
      <a className="navbar-item">Home</a>
      <a href="#companyHistory" className="navbar-item">Our History</a>
      <a href="#values-missions" className="navbar-item">Our Values & Mission</a>
      <a className="navbar-item">Our Clients</a>
      <a className="navbar-item">Contact Us</a>
    </div>
    <div className="navbar-end">
      <div className="navbar-item">
        <div className="buttons">
          <a className="button is-primary">
            <strong>Contact Us</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
      )
};
  
export default Navbar;