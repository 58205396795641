import 'bulma/css/bulma.min.css';
import '../App.css'
import React from 'react';


const RightBgHero = ({text, bg}) => {
    return (
<section id="companyHistory" class="hero is-fullheight">
  <div class="columns is-vcentered">
    <div class="column">
        <div class="container is-fluid has-text-centered">
          <p 
          class="title">
            {text.title}
          </p>
          <p class="subtitle">
            {text.subtitle}​
          </p>
        </div>
    </div>
    <div 
    class="column hero-right-bg"
    style={{
        backgroundImage: bg.url,
        // backgroundImage: `url(${props.imgObj.url})`,
        minHeight: "100vh",
        backgroundSize: "cover"
    }}>
    </div>
  </div>
</section>
    )
}

export default RightBgHero;