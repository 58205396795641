import "bulma/css/bulma.min.css";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import RightBgHero from "./components/right-bg-hero";
import LeftBgHero from "./components/left-bg-hero";
import LogoCarousel from "./components/logo-carousel";
import Map from "./components/contactSection/map";
import ContactInfo from "./components/contactSection/contactinfo";

const App = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <RightBgHero
        text={{
          title: "Company History",
          subtitle:
            "\nThe company Tritex was founded in 1994 in Tirana, Albania.  It is a garment production company who offers a variety of services from production of work-wear, swimwear, intimo, pyjamas, coats and so on. \n \n With more than 25 years of experience we offer large scale manufacturing service for large and small brands. A leader in the market and credited with several quality management certificates. Our vision for the future is to continue producing quality garments while maintaining our standards and while opting for sustainability. \n In the near future we aim to expand our areas of production and to offer other services within the industry. \n To continue to ensure a healthy, productive and harmonious community within the company.",
        }}
        bg={{
          url: 'url("/tritex_photos/1.jpeg")',
        }}
      />
      <LeftBgHero
        text={{
          title: "Our Values & Mission",
          subtitle:
            "\nTo continue to establish and strengthen long term and mutually beneficial relationships with our customers, business associates and suppliers. \n \n To maximize our economic potential across all the areas of involvement, through the expertise of the company’s management and personnel structures. \n \n To enhance our position as leader within the industry , we are primarily focused on continuing to deliver the finest products on a global basis. \n \n While ensuring to remain economically competitive, we constantly search opportunistic investments to provide our customers with a custom-made service and access to an ever-increasing product range, that in turn will maximize returns. Within our community our goal is to provide a healthy, happy and motivating environment.",
        }}
        bg={{
          url: 'url("/tritex_photos/2.jpeg")',
        }}
      />
      <LogoCarousel />
      <div className="container">
        <div className="columns is-centered mt-6 pt-6">
          <div className="column">
            <Map />
          </div>
          <div className="column">
            <ContactInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
