import "bulma/css/bulma.min.css";
import "./../../App.css";
import React from "react";
import ContactCards from "./contactcards";

const ContactInfo = () => {
  return (
    <div>
      <p className="title has-text-centered">Contact Info</p>
      <ContactCards 
      text={{
        title: 'Call Us',
        subtitle: '+355 68 398 7309',
        url: 'tel:+355 68 398 7309'
      }} />
      <ContactCards 
      text={{
        title: 'Email Us',
        subtitle: 'tritex.shpk@yahoo.com',
        url: 'mailto:tritex.shpk@yahoo.com'
      }} />
      <ContactCards 
      text={{
        title: 'Our Address',
        subtitle: 'Rruga 5 Maji, Tirana, Albania',
        url: 'https://www.google.com/maps/place/Rruga+5+Maji,+Tirana,+Albania/@41.3431797,19.8234902,17z/data=!3m1!4b1!4m5!3m4!1s0x13503169282ae1f5:0x32ebb8bdbef8024a!8m2!3d41.3431797!4d19.8256789'
      }} />
    </div>
  );
};

export default ContactInfo;
