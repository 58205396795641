import 'bulma/css/bulma.min.css';
import '../App.css'


const LogoCarousel = () => {
    return (
        <div 
        className='container has-text-centered my-4 pt-4'>
            <p 
            class="title has-text-centered pt-6">
                Our Clients
            </p>
            <img 
                src='/tritex_partner_logos/1.jpg'
                className='partner-logos'
            />
            <img 
                src='/tritex_partner_logos/3.jpg'
                className='partner-logos'
            />
                        <img 
                src='/tritex_partner_logos/4.png'
                className='partner-logos'
            />
                        <img 
                src='/tritex_partner_logos/5.png'
                className='partner-logos'
            />
                        <img 
                src='/tritex_partner_logos/6.png'
                className='partner-logos'
            />
                        <img 
                src='/tritex_partner_logos/7.png'
                className='partner-logos'
            />
                        <img 
                src='/tritex_partner_logos/8.png'
                className='partner-logos'
            />
        </div>
    )
}

export default LogoCarousel;