import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 41.3431797,
  lng: 19.8234849
};

class GMap extends Component {
  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyAKUT3dWHi0qg8TB7drLsXVA3-dT1R3swc"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default GMap;