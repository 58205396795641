import "bulma/css/bulma.min.css";
import "./../../App.css";
import React from "react";

const ContactCards = ({text}) => {
  return (
    <div>
      <div class="card py-2 my-2">
        <header class="card-header">
          <p class="card-header-title">{text.title}</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </header>
        <div class="card-content">
          <div class="content"><a href={text.url}>{text.subtitle}</a></div>
        </div>
      </div>
    </div>
  );
};

export default ContactCards;
